import React, { useContext, useEffect, useRef } from "react";
import { ButtonContained, CustomText, Input, Select } from "../../components";
import BewikiBG from "../../assets/svg/bewiki-home-bg-composition.svg";
import { FormFull } from "form-full";
import { fonts, SVG } from "../../config";
import { StorageContext } from "../../contexts/StorageContext";

import { hooks, masks, validations } from "../../utils";
import Styles from "./styles";
import { customModal } from "../../components/modals/utils";
import { CircularProgress, Grid, Switch, withStyles } from "@material-ui/core";
import { api } from "../../services";
import { useLocation, useNavigate } from "react-router-dom";
import AddressFields from "./components/address";
import { paths } from "../../navigation/navigate";
import ExtraGuest from "./components/extraGuest";
import nationalityList from "./components/nationalityList";
import GuestFields from "./components/guestData";
import HeaderHome from "./components/headerHome";
import LocationData from "./components/locationData";
import AddressFieldsExtra from "./components/addressExtra";
import alerts from "../../utils/alerts";
type Options = Array<{
  label: string;
  value: string;
  fullName?: string;
  id?: string;
}>;

const profissões = [
  { value: "Contador", label: "Contador" },
  { value: "Arquiteto", label: "Arquiteto" },
  { value: "Padeiro", label: "Padeiro" },
  { value: "Banqueiro", label: "Banqueiro" },
  { value: "Barbeiro", label: "Barbeiro" },
  { value: "Pedreiro", label: "Pedreiro" },
  { value: "Motorista de ônibus", label: "Motorista de ônibus" },
  { value: "Açougueiro", label: "Açougueiro" },
  { value: "Zelador", label: "Zelador" },
  { value: "Carpinteiro", label: "Carpinteiro" },
  { value: "Chefe de cozinha", label: "Chefe de cozinha" },
  { value: "Motorista", label: "Motorista" },
  { value: "Economista", label: "Economista" },
  { value: "Eletricista", label: "Eletricista" },
  { value: "Engenheiro", label: "Engenheiro" },
  { value: "Bombeiro", label: "Bombeiro" },
  { value: "Comissária de bordo", label: "Comissária de bordo" },
  { value: "Florista", label: "Florista" },
  { value: "Ourives", label: "Ourives" },
  { value: "Designer gráfico", label: "Designer gráfico" },
  { value: "Cabeleireiro", label: "Cabeleireiro" },
  { value: "Jornalista", label: "Jornalista" },
  { value: "Juiz", label: "Juiz" },
  { value: "Advogado", label: "Advogado" },
  { value: "Bibliotecário", label: "Bibliotecário" },
  { value: "Salva-vidas", label: "Salva-vidas" },
  { value: "Mecânico", label: "Mecânico" },
  { value: "Fotógrafo", label: "Fotógrafo" },
  { value: "Piloto", label: "Piloto" },
  { value: "Médico", label: "Médico" },
  { value: "Concierge", label: "Concierge" },
  { value: "Outros", label: "Outros" },
];

function Home() {
  const location = useLocation();
  const navigation = useNavigate();
  const [isMounted, setMount] = React.useState<boolean>(false);

  const { addData } = useContext<any>(StorageContext);
  const { loading, call } = hooks.useRequest();
  const [subLoading, setSubLoading] = React.useState<any>(false);

  const [docId, setDocId] = React.useState<any>(1);
  const [docIdExtra, setDocIdExtra] = React.useState<any>(1);
  const [formRef, setFormRef] = React.useState<any>(null);

  const [nf, setNf] = React.useState<Boolean>(false);
  const [extra, setExtra] = React.useState<Boolean>(true);
  const [formValue, setFormValue] = React.useState<any>(null);
  const [locValue, setLocValue] = React.useState<any>(null);

  const [internationalAddress, setInternationalAddress] = React.useState<{
    address: boolean;
    extraAddress: boolean;
  }>({ address: false, extraAddress: false });
  const [cities, setCities] = React.useState<Options>([]);
  const [states, setStates] = React.useState<Options>([]);
  const [countries, setCountries] = React.useState<Options>([]);
  const [stateSelected, setStateSelected] = React.useState<Boolean>(false);
  const [stateSelectedExtra, setStateSelectedExtra] =
    React.useState<Boolean>(false);

  const [citiesExtra, setCitiesExtra] = React.useState<Options>([]);
  const [statesExtra, setStatesExtra] = React.useState<Options>([]);
  const [countriesExtra, setCountriesExtra] = React.useState<Options>([]);
  const [copy, setCopy] = React.useState<Boolean>(false);

  const [copyData, setCopyData] = React.useState<boolean>(false);
  const [extraLoading, setExtraLoading] = React.useState<Boolean>(false);
  const focusView = useRef<any>(null);

  const docType = [
    { label: "CPF", value: 1 },
    { label: "Passaporte", value: 4 },
  ];

  const trevelType = [
    { label: "Negócios ", value: 2 },
    { label: "Lazer ", value: 3 },
  ];

  const Mount = React.useCallback(async () => {
    if (location?.search) {
      call(
        null,
        api.getGuest(location?.search?.replace("?guestCode=", "")),
        async (response) => {
          if (response.ok) {
            interface ListItem {
              label: string;
              value: string;
              fullName?: string;
            }

            let countriesList: ListItem[] = [];
            let statesList: ListItem[] = [];
            let citiesList: ListItem[] = [];

            let countriesListE: ListItem[] = [];
            let statesListE: ListItem[] = [];
            let citiesListE: ListItem[] = [];

            await call(null, api.getStates(), (response) => {
              if (response.ok) {
                statesList = response?.data?.map((item) => ({
                  label: item.initials,
                  value: item.id,
                  fullName: item.name,
                }));

                setStates(statesList);
                setStatesExtra(statesList);
              }
            });

            await call(null, api.getCountry(), (response) => {
              if (response.ok) {
                countriesList = response?.data?.map((item) => ({
                  label: item.name,
                  value: item.initials,
                  id: item.id,
                }));
                setCountries(countriesList);
                setCountriesExtra(countriesList);
              }
            });

            //---------------------------------------------------ENDEREÇO EXTRA ---------------------------------------------------

            let newData = {
              ...response.data,
              ...response?.data?.guestAddress,
              name: response?.data?.name + " " + response?.data?.lastName,
              birthDate: new Date(response?.data?.birthDate + "T12:00:00Z"),
              country: await countriesList?.find((item: ListItem) => {
                if (item?.value === response.data?.guestAddress?.country) {
                  return item;
                }
              })?.value,
              state:
                response.data?.guestAddress?.country === "BR" ||
                response.data?.guestAddress?.country === null ||
                response.data?.guestAddress?.country === undefined
                  ? await statesList?.find((item: ListItem) => {
                      if (
                        item?.label === response?.data?.guestAddress.state ||
                        item?.fullName === response?.data?.guestAddress.state
                      ) {
                        return item;
                      }
                    })?.value
                  : response?.data?.guestAddress.state,
            };

            if (Boolean(response?.data?.extraGuest?.id)) {
              newData = {
                ...newData,
                birthDateExtra: new Date(
                  response.data?.extraGuest?.birthDate + "T12:00:00Z"
                ),
                countryExtra: await countriesList?.find((item: ListItem) => {
                  if (
                    item?.value ===
                    response.data?.extraGuest?.guestAddress?.country
                  ) {
                    return item;
                  }
                })?.value,
                stateExtra:
                  response?.data?.extraGuest?.guestAddress?.country === "BR" ||
                  response?.data?.extraGuest?.guestAddress?.country === null ||
                  response?.data?.extraGuest?.guestAddress?.country ===
                    undefined
                    ? await statesList?.find((item: ListItem) => {
                        if (
                          item?.label ===
                            response.data?.extraGuest?.guestAddress?.state ||
                          item?.fullName ===
                            response.data?.extraGuest?.guestAddress?.state
                        ) {
                          return item;
                        }
                      })?.value
                    : response.data?.extraGuest?.guestAddress?.state,

                documentTypeExtra: response.data?.extraGuest?.documentType
                  ? response.data?.extraGuest?.documentType === 0
                    ? 1
                    : response.data?.extraGuest?.documentType
                  : 1,
                documentValueExtra: response.data?.extraGuest?.documentValue,

                nameExtra:
                  response.data?.extraGuest?.name +
                  " " +
                  response.data?.extraGuest?.lastName,

                emailExtra: response.data?.extraGuest?.email,

                genderExtra: response.data?.extraGuest?.gender,
                nationalityExtra: response.data?.extraGuest?.nationality,

                phoneExtra: response.data?.extraGuest?.phone,

                celphoneExtra: response.data?.extraGuest?.celphone,

                travelReasonExtra: response.data?.extraGuest?.travelReason,
                professionExtra: response.data?.extraGuest?.profession,

                numberExtra: response.data?.extraGuest?.guestAddress?.number,
                streetExtra: response.data?.extraGuest?.guestAddress?.street,
                complementExtra:
                  response.data?.extraGuest?.guestAddress?.complement,
                districtExtra:
                  response.data?.extraGuest?.guestAddress?.district,
                cityExtra: response.data?.extraGuest?.guestAddress?.city,
                postal_codeExtra:
                  response.data?.extraGuest?.guestAddress?.postal_code,
                beds: response.data?.bedType,
              };
            } else {
              setExtra(false);
            }

            if (
              response?.data?.guestAddress?.city &&
              (response.data?.guestAddress?.country === "BR" ||
                response.data?.guestAddress?.country === null ||
                response.data?.guestAddress?.country === undefined) &&
              newData?.state &&
              newData?.state !== null
            ) {
              await call(null, api.getCities(newData?.state), async (r) => {
                if (r.ok) {
                  setStateSelected(true);

                  citiesList = r?.data?.map((item) => ({
                    label: item.name,
                    value: item.name,
                  }));
                  setCities(citiesList);
                  newData.city = await citiesList?.find((item: ListItem) => {
                    if (item?.label === response?.data?.guestAddress.city) {
                      return item;
                    }
                  })?.value;
                }
              });
            } else {
              newData.city === response?.data?.guestAddress?.city;
            }

            if (
              response.data?.extraGuest?.guestAddress?.city &&
              (response?.data?.extraGuest?.guestAddress?.country === "BR" ||
                response?.data?.extraGuest?.guestAddress?.country === null ||
                response?.data?.extraGuest?.guestAddress?.country ===
                  undefined) &&
              newData?.stateExtra &&
              newData?.stateExtra !== null
            ) {
              await call(null, api.getCities(newData.stateExtra), async (r) => {
                if (r.ok) {
                  setStateSelected(true);

                  citiesList = r?.data?.map((item) => ({
                    label: item.name,
                    value: item.name,
                  }));
                  setCitiesExtra(citiesList);
                  newData.cityExtra = await citiesList?.find(
                    (item: ListItem) => {
                      if (
                        item?.label ===
                        response.data?.extraGuest?.guestAddress?.city
                      ) {
                        return item;
                      }
                    }
                  )?.value;
                }
              });

              setStateSelectedExtra(true);
            } else {
              newData.cityExtra ===
                response.data?.extraGuest?.guestAddress?.city;
            }

            setDocId(
              response?.data?.documentType
                ? response?.data?.documentType === 0
                  ? 1
                  : response?.data?.documentType
                : 1
            );
            setDocIdExtra(
              response?.data?.extraGuest?.documentType
                ? response?.data?.extraGuest?.documentType === 0
                  ? 1
                  : response?.data?.extraGuest?.documentType
                : 1
            );
            setNf(
              response?.data?.cnpj &&
                response?.data?.cnpj !== "" &&
                response?.data?.cnpj !== null
                ? true
                : false
            );
            setInternationalAddress({
              address:
                response?.data?.guestAddress?.country &&
                response.data?.guestAddress?.country !== "BR"
                  ? true
                  : false,
              extraAddress:
                response?.data?.extraGuest?.guestAddress?.country &&
                response?.data?.extraGuest?.guestAddress?.country !== "BR"
                  ? true
                  : false,
            });

            setFormValue(newData);
            setLocValue({
              checkOut: masks.formatDate(newData?.checkOut),
              checkIn: masks.formatDate(newData?.checkIn),
              loc:
                newData?.stayType &&
                newData?.stayType + " - " + newData?.stayNumber &&
                newData?.stayNumber,
            });
          }
        }
      );
    }
  }, [location?.search]);

  React.useEffect(() => {
    if (!isMounted) {
      Mount();
      setMount(true);
    }
  }, [Mount, isMounted]);

  useEffect(() => {
    if (formRef && isMounted && focusView) {
      formRef?.setFieldFocus("documentType");

      focusView?.current?.scrollIntoView();
    }
  }, [formRef, isMounted, focusView]);

  useEffect(() => {
    if (internationalAddress) {
      setExtraLoading(false);
    }
  }, [internationalAddress]);

  const Submit = async () => {
    const { data, hasError } = await formRef?.testErrorsAndReturnData();

    if (!hasError) {
      const fullName = data?.name?.split(" ");
      const firstName = fullName ? fullName[0] : [];
      fullName?.shift();
      const lastName = fullName?.join(" ");

      const fullNameExtra = data?.nameExtra?.split(" ");
      const firstNameExtra = fullNameExtra ? fullNameExtra[0] : [];
      fullNameExtra?.shift();
      const lastNameExtra = fullNameExtra?.join(" ");

      const newData = {
        documentType: data?.documentType,
        documentValue: data?.documentValue,
        name: firstName,
        lastName: lastName,
        email: data?.email,
        birthDate: masks.formatDateForm(data?.birthDate),
        gender: data?.gender,
        nationality: data?.nationality,
        vehicleLicensePlate: data?.vehicleLicensePlate
          ? data?.vehicleLicensePlate
          : null,
        vehicleModel: data?.vehicleModel ? data?.vehicleModel : null,
        phone: data?.phone,
        /*         data?.phone?.length=== 11 || data?.phone?.length === 12
          ? "55" + data?.phone
          : data?.phone, */
        celphone: data?.celphone,
        /*       data?.celphone?.length === 11 || data?.celphone?.length === 12
          ? "55" + data?.celphone
          : data?.celphone, */

        travelReason: data?.travelReason,
        profession: data?.profession,
        guestAddress: {
          number: data?.number,
          street: data?.street,
          complement: data?.complement,
          district: data?.district,
          city: data?.city,
          countryCode: data?.country,
          state:
            data?.country !== "BR" &&
            data?.country !== undefined &&
            data?.country !== null
              ? data?.state
              : states?.find((item) => {
                  if (item?.value === data?.state) {
                    return item;
                  }
                })?.label,
          country: data?.country,
          postal_code: data?.postal_code,
        },
        cnpj: data?.cnpj ? data?.cnpj : null,
        companyName: data?.companyName ? data?.companyName : null,

        bedType: data?.beds ? data?.beds : null,
        quantity: data?.beds ? (data?.beds === "single" ? 2 : 1) : null,

        extraGuest: !data?.emailExtra
          ? null
          : {
              documentType: data?.documentTypeExtra
                ? data?.documentTypeExtra === 0
                  ? 1
                  : data?.documentTypeExtra
                : 1,
              documentValue: data?.documentValueExtra,
              name: firstNameExtra,
              lastName: lastNameExtra,
              email: data?.emailExtra,
              birthDate: masks.formatDateForm(data?.birthDateExtra),
              gender: data?.genderExtra,
              nationality: data?.nationalityExtra,

              phone: data?.phoneExtra,
              /*               data?.phoneExtra?.length === 11 || data?.phoneExtra?.length === 12
                ? "55" + data?.phoneExtra
                : data?.phoneExtra, */

              celphone: data?.celphoneExtra,
              /*               data?.celphoneExtra?.length === 11 ||
              data?.celphoneExtra?.length === 12
                ? "55" + data?.celphoneExtra
                : data?.celphoneExtra, */

              travelReason: data?.travelReasonExtra,
              profession: data?.professionExtra,

              guestAddress: {
                number: data?.numberExtra,
                street: data?.streetExtra,
                complement: data?.complementExtra,
                district: data?.districtExtra,
                city: data?.cityExtra,
                countryCode: data?.countryExtra,
                state:
                  data?.countryExtra !== "BR" &&
                  data?.countryExtra !== null &&
                  data?.countryExtra !== undefined
                    ? data?.stateExtra
                    : states?.find((item) => {
                        if (item?.value === data?.stateExtra) {
                          return item;
                        }
                      })?.label,

                country: data?.countryExtra,
                postal_code: data?.postal_codeExtra,
              },
            },
      };

      customModal.setInfos(
        "Confirmar Ficha de Hóspede",
        [
          "Ao confirmar sua Ficha de Hóspede, daremos início ao processo de reserva do imóvel solicitado.",
          "Também estaremos entrando em contato a partir de seus meios de contato fornecidos para maiores informações sobre guias de hospedagem e próximos passos.",
          "Confirmar envio da Ficha de Hóspede?",
        ],
        {
          onClick: () => {
            customModal.close();

            call(
              null,
              api.saveGuest(
                location?.search?.replace("?guestCode=", ""),
                newData
              ),
              (response) => {}
            );

            call(
              null,
              api.sendGuest(
                location?.search?.replace("?guestCode=", ""),
                newData
              ),
              (response) => {
                if (response.ok) {
                  navigation(paths.landing + location?.search);
                  customModal.setInfos(
                    "Ficha de Hóspede Encaminhada",
                    [
                      "Sua ficha de hóspede foi encaminhada com sucesso!",
                      "Em breve estaremos entrando em contato para maiores orientações e guia de entrada ao imóvel no dia de seu check-in.",
                    ],
                    {
                      onClick: () => {
                        customModal.close();
                      },
                      label: "Ok, entendi",
                    }
                  );
                }
              }
            );
          },
          label: "Sim, Confirmar",
        },
        {
          onClick: () => {
            customModal.close();
          },
          label: "Cancelar",
        }
      );
    } else {
      if (formRef?.formFieldsHandler?.formFields) {
        let keysWithErrors =
          "Os seguintes campos estão incorretos ou vazios: \n\n";

        for (const key in formRef?.formFieldsHandler?.formFields) {
          if (formRef?.formFieldsHandler?.formFields[key].error === true) {
            keysWithErrors += `• ${formRef?.formFieldsHandler?.formFields[key]?.label}; \n`;
          }
        }

        alerts.alertWarning(keysWithErrors);
      }
    }
  };

  const onBlurSubmit = async () => {
    const { data, hasError } = await formRef?.testErrorsAndReturnData();

    const formFields = formRef.formFieldsHandler.formFields;

    Object.keys(formFields).forEach((key) => {
      const field = formFields[key];

      // Verifica se o campo contém a chave 'error' igual a true
      if (field?.error === true && data.hasOwnProperty(key)) {
        // Remove a chave do objeto data, se ela existir
        delete data[key];
      }
    });

    const fullName = data?.name?.split(" ");
    const firstName = fullName ? fullName[0] : [];
    fullName?.shift();
    const lastName = fullName?.join(" ");

    const fullNameExtra = data?.nameExtra?.split(" ");
    const firstNameExtra = fullNameExtra ? fullNameExtra[0] : [];
    fullNameExtra?.shift();
    const lastNameExtra = fullNameExtra?.join(" ");

    const newData = {
      vehicleLicensePlate: data?.vehicleLicensePlate
        ? data?.vehicleLicensePlate
        : null,
      vehicleModel: data?.vehicleModel ? data?.vehicleModel : null,
      documentType: data?.documentType ? data?.documentType : null,
      documentValue: data?.documentValue ? data?.documentValue : null,
      name: firstName ? firstName : null,
      lastName: lastName ? lastName : null,
      email: data?.email ? data?.email : null,
      birthDate: data?.birthDate ? masks.formatDateForm(data?.birthDate) : null,
      gender: data?.gender ? data?.gender : null,
      nationality:
        data?.nationality === -1 || data?.nationality === "-1"
          ? null
          : data?.nationality
          ? data?.nationality
          : null,
      phone: data?.phone ? data?.phone : null,
      celphone: data?.celphone ? data?.celphone : null,
      travelReason: data?.travelReason ? data?.travelReason : null,
      profession:
        data?.profession !== -1 || data?.profession !== "-1"
          ? data?.profession
          : null,
      guestAddress: {
        number: data?.number ? data?.number : null,
        street: data?.street ? data?.street : null,
        complement: data?.complement ? data?.complement : null,
        district: data?.district ? data?.district : null,
        city: data?.city ? data?.city : null,
        countryCode:
          data?.country === -1 || data?.country === "-1"
            ? null
            : data?.country
            ? data?.country
            : null,
        state:
          data?.country !== "BR" &&
          data?.country !== undefined &&
          data?.country !== null
            ? data?.state
            : states?.find((item) => item?.value === data?.state)?.label ||
              null,
        country:
          data?.country === -1 || data?.country === "-1"
            ? null
            : data?.country
            ? data?.country
            : null,
        postal_code: data?.postal_code ? data?.postal_code : null,
      },
      cnpj: data?.cnpj ? data?.cnpj : null,
      companyName: data?.companyName ? data?.companyName : null,
      bedType: data?.beds ? data?.beds : null,
      quantity: data?.beds ? (data?.beds === "single" ? 2 : 1) : null,
      extraGuest: !extra
        ? null
        : {
            documentType: data?.documentTypeExtra
              ? data?.documentTypeExtra === 0
                ? 1
                : data?.documentTypeExtra
              : 1,
            documentValue: data?.documentValueExtra
              ? data?.documentValueExtra
              : null,
            name: firstNameExtra ? firstNameExtra : null,
            lastName: lastNameExtra ? lastNameExtra : null,
            email: data?.emailExtra ? data?.emailExtra : null,
            birthDate: data?.birthDateExtra
              ? masks.formatDateForm(data?.birthDateExtra)
              : null,
            gender: data?.genderExtra ? data?.genderExtra : null,
            nationality:
              data?.nationalityExtra === -1 || data?.nationalityExtra === "-1"
                ? null
                : data?.nationalityExtra
                ? data?.nationalityExtra
                : null,
            phone: data?.phoneExtra ? data?.phoneExtra : null,
            celphone: data?.celphoneExtra ? data?.celphoneExtra : null,
            travelReason: data?.travelReasonExtra
              ? data?.travelReasonExtra
              : null,
            profession: data?.professionExtra ? data?.professionExtra : null,
            guestAddress: {
              number: data?.numberExtra ? data?.numberExtra : null,
              street: data?.streetExtra ? data?.streetExtra : null,
              complement: data?.complementExtra ? data?.complementExtra : null,
              district: data?.districtExtra ? data?.districtExtra : null,
              city: data?.cityExtra ? data?.cityExtra : null,
              countryCode:
                data?.countryExtra === -1 || data?.countryExtra === "-1"
                  ? null
                  : data?.countryExtra
                  ? data?.countryExtra
                  : null,
              state:
                data?.countryExtra !== "BR" &&
                data?.countryExtra !== null &&
                data?.countryExtra !== undefined
                  ? data?.stateExtra
                  : states?.find((item) => item?.value === data?.stateExtra)
                      ?.label || null,
              country:
                data?.countryExtra === -1 || data?.countryExtra === "-1"
                  ? null
                  : data?.countryExtra
                  ? data?.countryExtra
                  : null,
              postal_code: data?.postal_codeExtra
                ? data?.postal_codeExtra
                : null,
            },
          },
    };

    call(
      null,
      api.saveGuest(location?.search?.replace("?guestCode=", ""), newData),
      (response) => {}
    );
  };

  const nationList = nationalityList;

  return (
    <div
      style={{
        display: "flex",
        width: "100%",

        flexDirection: "column",

        justifyContent: "flex-start",
        alignItems: "flex-start",
        overflowY: "auto",
        overflowX: "hidden",

        paddingInline: "clamp(24px, 5vw, 100px)",
        backgroundColor: "#00796D",
        backgroundImage: `url(${BewikiBG})`,
        backgroundRepeat: "repeat",
        height: "100vh",
      }}
    >
      <Styles.Container>
        <HeaderHome focusView={focusView} navigation={navigation} />

        <Styles.ContainerForm>
          {subLoading ? (
            <div
              style={{
                width: "100%",
                height: "50vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            formValue && (
              <FormFull
                clearOnSubmit={false}
                submitOnClear={false}
                currentValues={formValue}
                formRef={setFormRef}
                onSubmit={() => {}}
              >
                <LocationData locValue={locValue} />

                <div
                  style={{
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    padding: "20px",
                  }}
                >
                  <div>
                    <CustomText
                      fontFamily={fonts.bold}
                      fontSize={2}
                      style={{ paddingBottom: "6px" }}
                    >
                      FICHA DE HÓSPEDE
                    </CustomText>
                    <CustomText
                      textColor="#00796D"
                      fontFamily={fonts.semibold}
                      fontSize={2}
                    >
                      O (*) denota campo obrigatório
                    </CustomText>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      marginBlock: "20px",
                      backgroundColor: "#303036",
                    }}
                  ></div>

                  <Grid container spacing={1}>
                    <GuestFields
                      onBlurSubmit={onBlurSubmit}
                      setExtraLoading={setExtraLoading}
                      copyData={copyData}
                      setFormValue={setFormValue}
                      profissões={profissões}
                      formRef={formRef}
                      docType={docType}
                      docId={docId}
                      setDocId={setDocId}
                      docIdExtra={docIdExtra}
                      setDocIdExtra={setDocIdExtra}
                      trevelType={trevelType}
                      nationList={nationList}
                      nf={nf}
                      setNf={setNf}
                      formValue={formValue}
                    />

                    {nf && (
                      <>
                        <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Input
                            name="cnpj"
                            label="CNPJ*"
                            mask={masks.CNPJ}
                            maskToSubmit={masks.removeNumberMask}
                            validation={validations.validateCNPJ}
                            required="*Campo Obrigatório"
                            customBlur={onBlurSubmit}
                          />
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Input
                            name="companyName"
                            label="Nome da empresa*"
                            required="*Campo Obrigatório"
                          />
                        </Grid> */}
                      </>
                    )}
                    {/* ########  Endereço ################################ */}

                    <AddressFields
                      setInternationalAddress={setInternationalAddress}
                      internationalAddress={internationalAddress}
                      formRef={formRef}
                      setStateSelected={setStateSelected}
                      setCities={setCities}
                      states={states}
                      formValue={formValue}
                      cities={cities}
                      setFormValue={setFormValue}
                      countries={countries}
                      stateSelected={stateSelected}
                      copy={extra && copy}
                      setCitiesExtra={setCitiesExtra}
                      setExtraLoading={setExtraLoading}
                      extraLoading={extraLoading}
                      onBlurSubmit={onBlurSubmit}
                    />

                    {/* ########  ACOMPANHANTE ################################ */}

                    <>
                      {extra && (
                        <>
                          <ExtraGuest
                            docId={docIdExtra}
                            setDocId={setDocIdExtra}
                            copyData={copyData}
                            setCopyData={setCopyData}
                            profissões={profissões}
                            nationalityList={nationList}
                            formRef={formRef}
                            loading={loading}
                            formValue={formValue}
                            setFormValue={setFormValue}
                            setStateSelected={setStateSelectedExtra}
                            setCitiesExtra={setCitiesExtra}
                            setCopy={setCopy}
                            extraLoading={extraLoading}
                            setExtraLoading={setExtraLoading}
                            setInternationalAddress={setInternationalAddress}
                            internationalAddress={internationalAddress}
                            onBlurSubmit={onBlurSubmit}
                          />
                          <AddressFieldsExtra
                            copyData={copyData}
                            formRef={formRef}
                            setStateSelected={setStateSelectedExtra}
                            setCities={setCitiesExtra}
                            states={statesExtra}
                            formValue={formValue}
                            cities={citiesExtra}
                            setFormValue={setFormValue}
                            countries={countriesExtra}
                            stateSelected={stateSelectedExtra}
                            copy={copy}
                            setCopy={setCopy}
                            extraLoading={extraLoading}
                            setExtraLoading={setExtraLoading}
                            setInternationalAddress={setInternationalAddress}
                            internationalAddress={internationalAddress}
                            onBlurSubmit={onBlurSubmit}
                          />
                        </>
                      )}
                    </>
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      gap: "8px",
                    }}
                  >
                    <ButtonContained
                      fullWidth={false}
                      loading={loading}
                      disabled={loading}
                      style={{ backgroundColor: "#00796D" }}
                      onClick={() => {
                        Submit();
                      }}
                    >
                      Confirmar
                    </ButtonContained>
                  </div>
                </div>
              </FormFull>
            )
          )}
        </Styles.ContainerForm>

        <Styles.ContainerVersion>
          <CustomText
            fontFamily={fonts.light}
            fontSize={1.25}
            style={{ opacity: 0.8 }}
          >
            {process.env.REACT_APP_ENV} - {process.env.REACT_APP_VERSION}
          </CustomText>
        </Styles.ContainerVersion>
      </Styles.Container>
    </div>
  );
}

export default Home;
