import { CircularProgress, Grid, Switch, withStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { convertToObject } from "typescript";
import { CustomText, Input, InputAutocomplete } from "../../../components";
import CustomTable from "../../../components/others/Table";
import { fonts, Spacing } from "../../../config";
import { api } from "../../../services";
import { hooks, masks, validations } from "../../../utils";
import Styles from "../styles";
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    marginBlock: theme.spacing(1.5),
  },
  switchBase: {
    padding: 2.5,
    color: "#00000054",
    "&$checked": {
      transform: "translateX(16px)",
      color: "#00796D",
      "& + $track": {
        backgroundColor: "#C4E8E4",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#C4E8E4",
      border: "none",
    },
  },
  thumb: {
    width: 20,
    height: 20,
  },
  track: {
    borderRadius: 26 / 2,
    border: "none",
    backgroundColor: "#EBEBEB",
    opacity: 1,
    transition: theme.transitions.create(["background-color"]),
  },
  checked: {},
  focusVisible: {},
}))((props: any) => {
  const { classes, ...switchProps } = props;
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
function AddressFields({
  formRef,
  setStateSelected,
  setCities,
  states,
  formValue,
  cities,
  setFormValue,
  countries,
  stateSelected,
  copy,

  setCitiesExtra,
  setExtraLoading,
  extraLoading,

  setInternationalAddress,
  internationalAddress,
  copyData,
  onBlurSubmit,
}: any) {
  const { loading, call, falseLoading } = hooks.useRequest();

  const loadCities = async (stateId: string, copy: boolean): Promise<any[]> => {
    if (stateId === null) {
      setStateSelected(false);
      setCities([]);

      if (copy) {
        setCitiesExtra([]);
      }
      return [];
    }

    return new Promise<any[]>(async (resolve, reject) => {
      await call(null, api.getCities(stateId), (response) => {
        if (response.ok) {
          setStateSelected(true);
          const citiesList = response?.data.map((item) => ({
            label: item.name,
            value: item.name,
          }));
          setCities(citiesList);
          if (copy) {
            setCitiesExtra(citiesList);
          }
          resolve(citiesList);
        } else {
          reject([]);
        }
      });
    });
  };

  const showCepError = (data: any) => {};

  const getAddressByCep = async (cep: string) => {
  
    const hasError = validations.isValidCEP(cep);
    const formData = await formRef?.getValues();

    copy && setExtraLoading(true);
    if (!hasError) {
      await call(
        null,
        api.getAddressCep(cep),
        async (response) => {
          if (response.ok) {
            let newStateId = await states.find(
              (a) => a.fullName === response?.data?.state
            );

            if (newStateId) {
              let holdCitiesList = await loadCities(newStateId.value, copy);

              let newData: any = {
                number: response?.data?.number,
                street: response?.data?.street,
                complement: response?.data?.complement,
                district: response?.data?.district,
                city: await holdCitiesList?.find((item) => {
                  if (
                    item?.label === response?.data?.city ||
                    item?.value === response?.data?.city
                  ) {
                    return item;
                  }
                })?.value,
                state: newStateId?.value,
                country: "BR",
                postal_code: cep,
              };

              if (copy) {
                newData = {
                  ...newData,
                  numberExtraExtra: response?.data?.number,
                  streetExtra: response?.data?.street,
                  complementExtra: response?.data?.complement,
                  districtExtra: response?.data?.district,
                  cityExtra: await holdCitiesList?.find((item) => {
                    if (
                      item?.label === response?.data?.city ||
                      item?.value === response?.data?.city
                    ) {
                      return item;
                    }
                  })?.value,
                  stateExtra: newStateId?.value,
                  countryExtra: "BR",
                  postal_codeExtra: cep,
                };
              }
              setFormValue((old) => {
                return { ...old, ...formData, ...newData };
              });
              copy && setExtraLoading(false);
            } else {
              let newData = { postal_code: cep };

              if (copy) {
                newData["postal_codeExtra"] = cep;
              }
              copy && setExtraLoading(false);
              setFormValue((old) => {
                return { ...old, ...formData, ...newData };
              });
            }
          } else {
            let newData = { postal_code: cep };

            if (copy) {
              newData["postal_codeExtra"] = cep;
            }
            copy && setExtraLoading(false);
            setFormValue((old) => {
              return { ...old, ...formData, ...newData };
            });
          }
        },
        showCepError
      );
    } else {
      copy && setExtraLoading(false);
    }
  };

  const resetAddress = async () => {
    if (copy || copyData) {
      await formRef?.clearSpecificFields(
        [
          "numberExtra",
          "streetExtra",
          "complementExtra",
          "districtExtra",
          "cityExtra",
          "stateExtra",
          "countryExtra",
          "postal_codeExtra",
        ],
        false
      );
    }

    await formRef?.clearSpecificFields(
      [
        "number",
        "street",
        "complement",
        "district",
        "city",
        "state",
        "country",
        "postal_code",
      ],
      false
    );

    const formData = await formRef?.getValues();
    setExtraLoading(true);

    setFormValue((old) => {
      let newDataClear = {
        ...old,
        ...formData,
        stayType: old?.stayType,
        number: null,
        street: null,
        complement: null,
        district: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
      };

      if (copy || copyData) {
        newDataClear = {
          ...newDataClear,
          numberExtra: null,
          streetExtra: null,
          complementExtra: null,
          districtExtra: null,
          cityExtra: null,
          stateExtra: null,
          countryExtra: null,
          postal_codeExtra: null,
        };
      }

      return newDataClear;
    });
    falseLoading();
    setExtraLoading(false);
  };
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <CustomText
          textColor="#00796D"
          fontFamily={fonts.semibold}
          fontSize={2}
          style={{ paddingBottom: "14px" }}
        >
          ENDEREÇO
        </CustomText>
      </Grid>
      <Grid container item xs={12} sm={6} md={6} lg={3}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Styles.TitleInput>Endereço Internacional?</Styles.TitleInput>

          <IOSSwitch
            disabled={loading || extraLoading}
            checked={internationalAddress?.address}
            onChange={async (e) => {
              resetAddress();

              copy
                ? setInternationalAddress((old) => {
                    return {
                      address: e.target.checked,
                      extraAddress: e.target.checked,
                    };
                  })
                : setInternationalAddress((old) => {
                    return { ...old, address: e.target.checked };
                  });
            }}
            name="checkedInternational"
          />
        </div>
      </Grid>

      {internationalAddress?.address ? (
        <>
          <Grid
            style={{ display: "flex", alignItems: "flex-end" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Input
              required="*Campo Obrigatório"
              name="postal_code"
              label="Código Postal*"
              maskToSubmit={masks.removeNumberMask}
              customBlur={onBlurSubmit}
              onChange={(value) => {
                const values = formRef?.getValues();
                if (values && values.hasOwnProperty("postal_codeExtra")) {
                  copy && formRef.setValue("postal_codeExtra", value);
                }
              }}
            />
          </Grid>
          {loading ? (
            <>
              <Grid
                style={{ display: "flex", alignItems: "center" }}
                item
                xs={12}
                sm={8}
                md={8}
                lg={10}
              >
                <div style={{ width: "100%" }}>
                  <Skeleton variant="rect" width="100%" height={55} />
                </div>
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "center" }}
                item
                xs={12}
              >
                <div style={{ width: "100%" }}>
                  <Skeleton variant="rect" width="100%" height={55} />
                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={8}
                md={4}
                lg={3}
              >
                <InputAutocomplete
                  key="county1-address"
                  validation={validations.validateAutocomplete}
                  options={countries ? countries : []}
                  name="country"
                  label="País*"
                  required="*Campo Obrigatório"
                  defaultValue={formValue ? formValue?.country : null}
                  customOnChange={async (e, value) => {
                    const values = formRef?.getValues();
                    if (values && values.hasOwnProperty("countryExtra")) {
                      copy && (await formRef.setValue("countryExtra", value));
                    }

                    const formData = await formRef?.getValues();
                    copy && setExtraLoading(true);

                    copy &&
                      setFormValue((v) => {
                        return {
                          ...v,
                          ...formData,
                          countryExtra: value,
                        };
                      });
                  }}
                  customBlur={() => {
                    copy && setExtraLoading(false);
                    onBlurSubmit();
                  }}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={4}
                md={4}
                lg={1}
              >
                <Input
                  key="state1-address"
                  name="state"
                  label="UF*"
                  required="*Campo Obrigatório"
                  defaultValue={formValue ? formValue?.state : null}
                  onChange={async (value) => {
                    const values = formRef?.getValues();
                    if (values && values.hasOwnProperty("stateExtra")) {
                      copy && (await formRef.setValue("stateExtra", value));
                    }
                  }}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={12}
                md={4}
                lg={3}
              >
                <Input
                  key="city1-address"
                  name="city"
                  label="Cidade*"
                  required="*Campo Obrigatório"
                  defaultValue={formValue ? formValue?.city : null}
                  onChange={(value) => {
                    const values = formRef?.getValues();
                    if (values && values.hasOwnProperty("cityExtra")) {
                      copy && formRef.setValue("cityExtra", value);
                    }
                  }}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Input
                  key="district1-address"
                  required="*Campo Obrigatório"
                  name="district"
                  label="Bairro*"
                  onChange={(value, e) => {
                    const values = formRef?.getValues();
                    if (values && values.hasOwnProperty("districtExtra")) {
                      copy && formRef.setValue("districtExtra", value);
                    }
                  }}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={5}
              >
                <Input
                  key="street1-address"
                  required="*Campo Obrigatório"
                  name="street"
                  label="Logradouro*"
                  defaultValue={formValue ? formValue?.street : null}
                  onChange={(value, e) => {
                    const values = formRef?.getValues();
                    if (values && values.hasOwnProperty("streetExtra")) {
                      copy && formRef.setValue("streetExtra", value);
                    }
                  }}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={4}
                md={4}
                lg={2}
              >
                <Input
                  key="number1-address"
                  required="*Campo Obrigatório"
                  name="number"
                  label="Nº*"
                  onChange={(value, e) => {
                    const values = formRef?.getValues();
                    if (values && values.hasOwnProperty("numberExtra")) {
                      copy && formRef.setValue("numberExtra", value);
                    }
                  }}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={8}
                md={2}
                lg={2}
              >
                <Input
                  key="complement1-address"
                  required="*Campo Obrigatório"
                  name="complement"
                  label="Complemento*"
                  onChange={(value, e) => {
                    const values = formRef?.getValues();
                    if (values && values.hasOwnProperty("complementExtra")) {
                      copy && formRef.setValue("complementExtra", value);
                    }
                  }}
                  customBlur={onBlurSubmit}
                />
              </Grid>
            </>
          )}
        </>
      ) : (
        <>
          <Grid
            style={{ display: "flex", alignItems: "flex-end" }}
            item
            xs={12}
            sm={6}
            md={6}
            lg={2}
          >
            <Input
              key="postal2-address"
              required="*Campo Obrigatório"
              name="postal_code"
              label="CEP*"
              mask={masks.CEP}
              validation={validations.isValidCEP}
              maskToSubmit={masks.removeNumberMask}
              customBlur={getAddressByCep}
            />
          </Grid>
          {loading ? (
            <>
              <Grid
                style={{ display: "flex", alignItems: "center" }}
                item
                xs={12}
                sm={8}
                md={8}
                lg={10}
              >
                <div style={{ width: "100%" }}>
                  <Skeleton variant="rect" width="100%" height={55} />
                </div>
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "center" }}
                item
                xs={12}
              >
                <div style={{ width: "100%" }}>
                  <Skeleton variant="rect" width="100%" height={55} />
                </div>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={8}
                md={4}
                lg={3}
              >
                <InputAutocomplete
                  key="country-2-address"
                  validation={validations.validateAutocomplete}
                  name="country"
                  placeholder="Selecionar"
                  options={countries ? countries : []}
                  label="País*"
                  //required="*Campo Obrigatório"
                  defaultValue={formValue ? "BR" : -1}
                  readOnly
                  customOnChange={async (e, value) => {
                    const values = formRef?.getValues();
                    if (values && values.hasOwnProperty("countryExtra")) {
                      copy && formRef.setValue("countryExtra", value);
                    }

                    const formData = await formRef?.getValues();

                    copy && setExtraLoading(true);

                    copy &&
                      setFormValue((v) => {
                        return {
                          ...v,
                          ...formData,
                          countryExtra: value,
                        };
                      });
                  }}
                  customBlur={() => {
                    copy && setExtraLoading(false);
                    onBlurSubmit();
                  }}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={4}
                md={4}
                lg={1}
              >
                <InputAutocomplete
                  key="state-2-address"
                  validation={validations.validateAutocomplete}
                  name="state"
                  placeholder="Selecionar"
                  options={states ? states : []}
                  label="UF*"
                  required="*Campo Obrigatório"
                  customOnChange={async (e, value) => {
                    const formData = await formRef?.getValues();

                    const checkAndChange = async (key, newValue) => {
                      if (formData && formData.hasOwnProperty(key)) {
                        copy && (await formRef.setValue(key, newValue));
                      }
                    };

                    if (copy) {
                      await checkAndChange("state", value);
                      await checkAndChange("stateExtra", value);
                      await checkAndChange("city", -1);
                      await checkAndChange("cityExtra", -1);
                    } else {
                      await checkAndChange("state", value);
                      await checkAndChange("city", -1);
                    }

                    if (value !== "-1" && value !== -1 && value !== null) {
                      loadCities(value, copy);
                    }

                    copy && setExtraLoading(true);

                    copy
                      ? setFormValue((v) => {
                          return {
                            ...v,
                            ...formData,
                            state: value,
                            stateExtra: value,
                            city: -1,
                            cityExtra: -1,
                          };
                        })
                      : setFormValue((v) => {
                          return {
                            ...v,
                            ...formData,
                            state: value,

                            city: -1,
                          };
                        });
                  }}
                  defaultValue={formValue ? formValue?.state : -1}
                  customBlur={() => {
                    copy && setExtraLoading(false);
                    onBlurSubmit();
                  }}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={12}
                md={4}
                lg={3}
              >
                <InputAutocomplete
                  key="city-2-address"
                  validation={validations.validateAutocomplete}
                  name="city"
                  options={cities ? cities : []}
                  label="Cidade*"
                  required="*Campo Obrigatório"
                  disabled={!stateSelected}
                  placeholder="Selecionar"
                  defaultValue={formValue ? formValue?.city : -1}
                  customOnChange={async (e, value) => {
                    const formData = await formRef?.getValues();
                    const checkAndChange = async (key, newValue) => {
                      if (formData && formData.hasOwnProperty(key)) {
                        copy && (await formRef.setValue(key, newValue));
                      }
                    };

                    copy && (await checkAndChange("city", value));
                    copy && (await checkAndChange("cityExtra", value));
                    copy && setExtraLoading(true);

                    copy &&
                      setFormValue((v) => {
                        return {
                          ...v,
                          ...formData,
                          city: value,
                          cityExtra: value,
                        };
                      });
                  }}
                  customBlur={() => {
                    copy && setExtraLoading(false);
                    onBlurSubmit();
                  }}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
              >
                <Input
                  key="district-2-address"
                  required="*Campo Obrigatório"
                  name="district"
                  label="Bairro*"
                  onChange={(value, e) => {
                    const values = formRef?.getValues();
                    if (values && values.hasOwnProperty("districtExtra")) {
                      copy && formRef.setValue("districtExtra", value);
                    }
                  }}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={6}
                md={4}
                lg={5}
              >
                <Input
                  key="street-2-address"
                  required="*Campo Obrigatório"
                  name="street"
                  label="Logradouro*"
                  onChange={(value, e) => {
                    const values = formRef?.getValues();
                    if (values && values.hasOwnProperty("streetExtra")) {
                      copy && formRef.setValue("streetExtra", value);
                    }
                  }}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={4}
                md={2}
                lg={2}
              >
                <Input
                  key="number-2-address"
                  required="*Campo Obrigatório"
                  name="number"
                  label="Nº*"
                  onChange={(value, e) => {
                    const values = formRef?.getValues();
                    if (values && values.hasOwnProperty("numberExtra")) {
                      copy && formRef.setValue("numberExtra", value);
                    }
                  }}
                  customBlur={onBlurSubmit}
                />
              </Grid>
              <Grid
                style={{ display: "flex", alignItems: "flex-end" }}
                item
                xs={12}
                sm={8}
                md={2}
                lg={2}
              >
                <Input
                  key="complement-2-address"
                  required="*Campo Obrigatório"
                  name="complement"
                  label="Complemento*"
                  onChange={(value, e) => {
                    const values = formRef?.getValues();
                    if (values && values.hasOwnProperty("complementExtra")) {
                      copy && formRef.setValue("complementExtra", value);
                    }
                  }}
                  customBlur={onBlurSubmit}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}

export default AddressFields;
